import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"

import { Content } from "page_components/gallery"

const Gallery = ({ data }) => {
  const lang = "en"

  return (
    <Layout
      seo={{
        title: "Galeria",
        description: "",
      }}
      lang={lang}
    >
      <Content data={data?.allWpGallery?.nodes} lang={lang} />
    </Layout>
  )
}

export const query = graphql`
  query {
    allWpGallery(sort: { order: DESC, fields: date }) {
      nodes {
        id
        acfGallery {
          city
          image {
            localFile {
              publicURL
            }
          }
        }
      }
    }
  }
`

export default Gallery
