import "./styles.scss"
import "react-image-lightbox/style.css"

import React, { useState } from "react"
import Lightbox from "react-image-lightbox"
import LazyLoad from "react-lazyload"
import { Fade } from "react-awesome-reveal"

import t from "locale"

const Content = ({ data, lang }) => {
  const [cat, setCat] = useState(null)
  const [photoIndex, setPhotoIndex] = useState(0)
  const [isLightboxOpen, setIsLightboxOpen] = useState(false)

  const gallery =
    cat === null ? data : data.filter(item => item.acfGallery.city === cat)

  const chorzow_lenght = data.filter(item => item.acfGallery.city === "chorzow")
  const warszawa_lenght = data.filter(
    item => item.acfGallery.city === "warszawa"
  )
  const krakow_lenght = data.filter(item => item.acfGallery.city === "krakow")
  const gdynia_lenght = data.filter(item => item.acfGallery.city === "gdynia")

  return (
    <section className="gallery-content">
      <div className="container">
        <h2>{t("Zgłoszone prace z każdego miasta", lang)}</h2>
        <div className="gallery-content__categories">
          <button
            className={!cat ? "current" : ""}
            onClick={() => setCat(null)}
          >
            {t("Wszystkie", lang)}
          </button>
          <button
            className={cat === "chorzow" ? "current" : ""}
            onClick={() => setCat("chorzow")}
          >
            {t("Chorzów", lang)} ({chorzow_lenght.length})
          </button>
          <button
            className={cat === "warszawa" ? "current" : ""}
            onClick={() => setCat("warszawa")}
          >
            {t("Warszawa", lang)} ({warszawa_lenght.length})
          </button>
          <button
            className={cat === "krakow" ? "current" : ""}
            onClick={() => setCat("krakow")}
          >
            {t("Kraków", lang)} ({krakow_lenght.length})
          </button>
          <button
            className={cat === "gdynia" ? "current" : ""}
            onClick={() => setCat("gdynia")}
          >
            {t("Gdynia", lang)} ({gdynia_lenght.length})
          </button>
        </div>

        <div className="gallery-content__content">
          <div className="row">
            {data &&
              gallery?.map((item, index) => (
                <div className="col-lg-4 col-sm-6" key={index}>
                  <Fade triggerOnce="true">
                    <LazyLoad>
                      <a
                        href="/"
                        onClick={e => {
                          e.preventDefault()
                          setIsLightboxOpen(true)
                          setPhotoIndex(index)
                        }}
                        rel="gallery"
                      >
                        <div
                          className="gallery-content__item"
                          style={{
                            backgroundImage: `url('${item?.acfGallery?.image?.localFile?.publicURL}')`,
                          }}
                        >
                          <span>
                            {item.acfGallery.city === "chorzow"
                              ? t("Chorzów", lang)
                              : item.acfGallery.city === "warszawa"
                              ? t("Warszawa", lang)
                              : item.acfGallery.city === "krakow"
                              ? t("Kraków", lang)
                              : item.acfGallery.city === "gdynia"
                              ? t("Gdynia", lang)
                              : ""}
                          </span>
                        </div>
                      </a>
                    </LazyLoad>
                  </Fade>
                </div>
              ))}
          </div>
        </div>
      </div>

      {isLightboxOpen && (
        <Lightbox
          mainSrc={gallery[photoIndex].acfGallery?.image?.localFile?.publicURL}
          nextSrc={
            gallery[(photoIndex + 1) % gallery.length].acfGallery?.image
              ?.localFile?.publicURL
          }
          prevSrc={
            gallery[(photoIndex + gallery.length - 1) % gallery.length]
              .acfGallery?.image?.localFile?.publicURL
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + gallery.length - 1) % gallery.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % gallery.length)
          }
        />
      )}
    </section>
  )
}

export default Content
